import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { Many2One } from "@app/models";
import { AuthService } from "@app/services/auth.service";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { concatLatestFrom } from "@ngrx/operators";
import { Store } from "@ngrx/store";
import { box, unbox } from "ngrx-forms";
import { map, tap } from "rxjs/operators";
import { AuthApiActions } from "../actions";
import { selectCompany, selectCompanyForm } from "../selectors";
import { CompanyApiActions, CompanyPutPageActions } from "./actions";

@Injectable()
export class CompanyEffects {
  constructor(
    private actions$: Actions,
    private store: Store,
    private router: Router,
    private authService: AuthService
  ) {}

  setCompanies$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthApiActions.init),
      map(() =>
        CompanyApiActions.setCompanies({
          selectedId: this.authService.getCompany(),
          companies: this.authService.getCompanies(),
        })
      )
    )
  );

  setFormControls$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CompanyPutPageActions.setCompany),
      concatLatestFrom(() => this.store.select(selectCompany)),
      map(([, company]) =>
        CompanyApiActions.setFormControls({
          companyForm: {
            company_id: company ? box({ ...new Many2One(company) }) : box(null),
          },
        })
      )
    )
  );

  updateCompany$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CompanyPutPageActions.updateCompany),
      concatLatestFrom(() => this.store.select(selectCompanyForm)),

      tap(([, form]) => {
        const values = unbox(form.value);
        const companyId = values.company_id.id.toString();
        this.authService.storeCompany(companyId);
      }),

      map(() => AuthApiActions.setCompanySuccess()),

      tap(() => {
        this.router.navigate(["/"], { replaceUrl: true });
      })
    )
  );
}
