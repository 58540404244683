import { Injectable } from "@angular/core";
import { CanActivate, CanLoad, Router } from "@angular/router";
import { AuthService } from "@app/services";

@Injectable({
  providedIn: "root",
})
export class AuthGuard implements CanActivate {
  constructor(private authService: AuthService, private router: Router) {}

  canActivate() {
    if (this.authService.isLoggedIn()) {
      this.router.navigate(["/operations"]);
    }
    return !this.authService.isLoggedIn();
  }
}

@Injectable({
  providedIn: "root",
})
export class AppGuard implements CanActivate, CanLoad {
  constructor(private authService: AuthService, private router: Router) {}

  canActivate() {
    return this.canLoad();
  }

  canLoad() {
    if (!this.authService.isLoggedIn()) {
      console.error("can load not logged in...");

      this.router.navigate(["/auth"]);
    }
    return this.authService.isLoggedIn();
  }
}
