import { inject } from "@angular/core";
import { CanActivateFn } from "@angular/router";
import { ToastService } from "@app/services";
import { StateStatus } from "@app/state/model";
import { selectDiveLogsStatus } from "@app/state/selectors";
import { Store } from "@ngrx/store";
import { filter, map, take, tap } from "rxjs/operators";

export const logGuard: CanActivateFn = () => {
  const store = inject(Store);
  const toast = inject(ToastService);
  return store.select(selectDiveLogsStatus).pipe(
    tap((status) => {
      switch (status) {
        case StateStatus.Pending:
          toast.show("Dive Logs pending to load...");
          break;
        case StateStatus.Loading:
          toast.show("Dive Logs loading...");
          break;
        case StateStatus.Error:
          toast.show("Dive Logs failed to load...");
          break;
      }
    }),
    filter((status) => status === StateStatus.Success),
    map(() => true),
    take(1)
  );
};
