import { Routes } from "@angular/router";
import {
  AppGuard,
  AttachmentGuard,
  AuthGuard,
  checklistGuard,
  ChecklistLineGuard,
  leavePageGuard,
  logGuard,
  logLineGuard,
  OperationGuard,
  RiskAssessmentGuard,
  ToolboxTalkGuard,
} from "./guards";
import { checklistTemplateGuard } from "./guards/checklist.template.guard";
import { logTemplateGuard } from "./guards/log.template.guard";
import { offlineQueueGuard } from "./guards/offline-queue.guard";

// TODO template guards for create/edit pages

export const routes: Routes = [
  {
    path: "",
    pathMatch: "full",
    redirectTo: "operations",
  },

  {
    path: "auth",
    loadComponent: () =>
      import("./components/auth/auth.page").then((m) => m.AuthPage),
    canActivate: [AuthGuard],
  },

  {
    path: "operations",
    canLoad: [AppGuard],
    canActivate: [AppGuard, offlineQueueGuard],
    children: [
      {
        path: "",
        canActivate: [OperationGuard],
        loadComponent: () =>
          import(
            "./components/dive/operation/operation-list/operation-list.page"
          ).then((m) => m.OperationListPage),
      },
      {
        path: "view/:operationId",
        canActivate: [
          OperationGuard,
          RiskAssessmentGuard,
          ToolboxTalkGuard,
          checklistGuard,
          logGuard,
          AttachmentGuard,
        ],
        loadComponent: () =>
          import(
            "./components/dive/operation/operation-view/operation-view.page"
          ).then((m) => m.OperationViewPage),
      },
      {
        path: "edit/:operationId",
        canActivate: [
          OperationGuard,
          RiskAssessmentGuard,
          ToolboxTalkGuard,
          checklistGuard,
          logGuard,
          AttachmentGuard,
        ],
        canDeactivate: [leavePageGuard],
        loadComponent: () =>
          import(
            "./components/dive/operation/operation-put/operation-put.page"
          ).then((m) => m.OperationPutPage),
      },
      {
        path: "create",
        canDeactivate: [leavePageGuard],
        loadComponent: () =>
          import(
            "./components/dive/operation/operation-put/operation-put.page"
          ).then((m) => m.OperationPutPage),
      },
    ],
  },

  {
    path: "toolbox-talks",
    canLoad: [AppGuard],
    canActivate: [AppGuard, OperationGuard, ToolboxTalkGuard],
    children: [
      {
        path: "view/:talkId",
        loadComponent: () =>
          import(
            "./components/dive/toolbox-talk/toolbox-talk-view/toolbox-talk-view.page"
          ).then((m) => m.ToolboxTalkViewPage),
      },
      {
        path: "edit/:talkId",
        canDeactivate: [leavePageGuard],
        loadComponent: () =>
          import(
            "./components/dive/toolbox-talk/toolbox-talk-put/toolbox-talk-put.page"
          ).then((m) => m.ToolboxTalkPutPage),
      },
      {
        path: "operation/:operationId",
        loadComponent: () =>
          import(
            "./components/dive/toolbox-talk/toolbox-talk-list/toolbox-talk-list.page"
          ).then((m) => m.ToolboxTalkListPage),
      },
    ],
  },

  {
    path: "checklists",
    canLoad: [AppGuard],
    canActivate: [AppGuard],
    children: [
      {
        path: "view/:checklistId",
        canActivate: [OperationGuard, checklistGuard, ChecklistLineGuard],
        loadComponent: () =>
          import(
            "./components/dive/checklist/checklist-view/checklist-view.page"
          ).then((m) => m.ChecklistViewPage),
      },
      {
        path: "operation",
        canActivate: [OperationGuard, checklistGuard, ChecklistLineGuard],
        loadComponent: () =>
          import(
            "./components/dive/checklist/checklist-list/checklist-list.page"
          ).then((m) => m.ChecklistListPage),
      },
      {
        path: "line",
        canActivate: [OperationGuard, checklistGuard, ChecklistLineGuard],
        children: [
          {
            path: "view/:lineId",
            loadComponent: () =>
              import(
                "./components/dive/checklist/checklist-line/checklist-line-view/checklist-line-view.page"
              ).then((m) => m.ChecklistLineViewPage),
          },
          {
            path: "edit/:lineId",
            canActivate: [checklistTemplateGuard],
            canDeactivate: [leavePageGuard],
            loadComponent: () =>
              import(
                "./components/dive/checklist/checklist-line/checklist-line-put/checklist-line-put.page"
              ).then((m) => m.ChecklistLinePutPage),
          },
          {
            path: "create/:checklistId",
            canActivate: [checklistTemplateGuard],
            canDeactivate: [leavePageGuard],
            loadComponent: () =>
              import(
                "./components/dive/checklist/checklist-line/checklist-line-put/checklist-line-put.page"
              ).then((m) => m.ChecklistLinePutPage),
          },
        ],
      },
    ],
  },

  {
    path: "dive-logs",
    canLoad: [AppGuard],
    canActivate: [AppGuard],
    children: [
      {
        path: "view/:logId",
        canActivate: [OperationGuard, logGuard],
        loadComponent: () =>
          import("./components/dive/log/log-view/log-view.page").then(
            (m) => m.LogViewPage
          ),
      },
      {
        path: "operation/:operationId",
        canActivate: [OperationGuard, logGuard],
        loadComponent: () =>
          import("./components/dive/log/log-list/log-list.page").then(
            (m) => m.LogListPage
          ),
      },
      {
        path: "line",
        canActivate: [OperationGuard, logGuard, logLineGuard],
        children: [
          {
            path: "view/:logLineId",
            loadComponent: () =>
              import(
                "./components/dive/log/log-line/log-line-view/log-line-view.page"
              ).then((m) => m.LogLineViewPage),
          },
          {
            path: "edit/:logLineId",
            canActivate: [logTemplateGuard],
            canDeactivate: [leavePageGuard],
            loadComponent: () =>
              import(
                "./components/dive/log/log-line/log-line-put/log-line-put.page"
              ).then((m) => m.LogLinePutPage),
          },
          {
            path: "create/:logId",
            canActivate: [logTemplateGuard],
            canDeactivate: [leavePageGuard],
            loadComponent: () =>
              import(
                "./components/dive/log/log-line/log-line-put/log-line-put.page"
              ).then((m) => m.LogLinePutPage),
          },
        ],
      },
    ],
  },

  {
    path: "risk-assessments",
    canLoad: [AppGuard],
    canActivate: [AppGuard, OperationGuard, RiskAssessmentGuard],
    children: [
      {
        path: "view/:assessmentId",
        loadComponent: () =>
          import(
            "./components/dive/risk-assessment/risk-assessment-view/risk-assessment-view.page"
          ).then((m) => m.RiskAssessmentViewPage),
      },
      {
        path: "edit/:assessmentId",
        canDeactivate: [leavePageGuard],
        loadComponent: () =>
          import(
            "./components/dive/risk-assessment/risk-assessment-put/risk-assessment-put.page"
          ).then((m) => m.RiskAssessmentPutPage),
      },
    ],
  },

  {
    path: "timesheets/operation",
    canLoad: [AppGuard],
    canActivate: [AppGuard, OperationGuard],
    children: [
      {
        path: ":operationId",
        loadComponent: () =>
          import(
            "./components/dive/timesheet/operation-timesheet-list/operation-timesheet-list.page"
          ).then((m) => m.OperationTimesheetListPage),
      },
      {
        path: "edit/:operationId",
        canDeactivate: [leavePageGuard],
        loadComponent: () =>
          import(
            "./components/dive/timesheet/operation-timesheet-put/operation-timesheet-put.page"
          ).then((m) => m.OperationTimesheetPutPage),
      },
    ],
  },

  {
    path: "attachments",
    canLoad: [AppGuard],
    canActivate: [AppGuard, AttachmentGuard],
    children: [
      {
        path: "",
        loadComponent: () =>
          import(
            "./components/dive/attachment/attachment-list/attachment-list.page"
          ).then((m) => m.AttachmentListPage),
      },
      {
        path: "view/:attachmentId",
        loadComponent: () =>
          import(
            "./components/dive/attachment/attachment-view/attachment-view.page"
          ).then((m) => m.AttachmentViewPage),
      },
      {
        path: "edit/:attachmentId",
        canDeactivate: [leavePageGuard],
        loadComponent: () =>
          import(
            "./components/dive/attachment/attachment-put/attachment-put.page"
          ).then((m) => m.AttachmentPutPage),
      },
      {
        path: "create/:operationId",
        canDeactivate: [leavePageGuard],
        loadComponent: () =>
          import(
            "./components/dive/attachment/attachment-put/attachment-put.page"
          ).then((m) => m.AttachmentPutPage),
      },
      {
        path: "operation/:operationId",
        loadComponent: () =>
          import(
            "./components/dive/attachment/operation-attachment-list/operation-attachment-list.page"
          ).then((m) => m.OperationAttachmentListPage),
      },
    ],
  },

  {
    path: "settings",
    canLoad: [AppGuard],
    canActivate: [AppGuard],
    children: [
      {
        path: "",
        loadComponent: () =>
          import("./components/settings/settings.page").then(
            (m) => m.SettingsPage
          ),
      },

      {
        path: "company/view",
        loadComponent: () =>
          import(
            "./components/settings/company/company-view/company-view.page"
          ).then((m) => m.CompanyViewPage),
      },
      {
        path: "company/edit",
        loadComponent: () =>
          import(
            "./components/settings/company/company-put/company-put.page"
          ).then((m) => m.CompanyPutPage),
      },

      {
        path: "offline-queue",
        loadComponent: () =>
          import("./components/settings/offline-queue/offline-queue.page").then(
            (m) => m.OfflineQueuePage
          ),
      },
    ],
  },
];
