import { Injectable, inject } from "@angular/core";
import { ApiService } from "@app/api/api.service";
import { CHECKLIST_TEMPLATE_MODEL, ChecklistTemplate } from "@app/models";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { of } from "rxjs";
import { catchError, map, switchMap } from "rxjs/operators";
import { AuthApiActions } from "../actions";
import { ChecklistTemplateApiActions } from "./actions";

@Injectable()
export class ChecklistTemplateEffects {
  private apiService = inject(ApiService);
  private actions$ = inject(Actions);

  constructor() {}

  loadData$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthApiActions.load),
      map(() => ChecklistTemplateApiActions.searchChecklistTemplates())
    )
  );

  searchCheckListTemplates$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ChecklistTemplateApiActions.searchChecklistTemplates),
      switchMap(() =>
        this.apiService.search(CHECKLIST_TEMPLATE_MODEL).pipe(
          map((apiData) =>
            ChecklistTemplateApiActions.searchChecklistTemplatesSuccess({
              checklistTemplates: apiData.map(
                (data) => new ChecklistTemplate(data)
              ),
            })
          ),
          catchError((error) =>
            of(
              ChecklistTemplateApiActions.searchChecklistTemplatesFailure({
                error,
              })
            )
          )
        )
      )
    )
  );
}
