import { Injectable } from "@angular/core";
import { ApiService } from "@app/api/api.service";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { of } from "rxjs";
import { catchError, exhaustMap, map, switchMap } from "rxjs/operators";
import { AuthApiActions, OfflineQueueApiActions } from "../actions";

@Injectable()
export class OfflineQueueEffects {
  constructor(private actions$: Actions, private apiService: ApiService) {}

  searchOfflineQueue$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthApiActions.load, OfflineQueueApiActions.searchOfflineQueue),
      switchMap(() =>
        this.apiService.searchOfflineQueue().pipe(
          map((enqueuedGroups) =>
            OfflineQueueApiActions.searchOfflineQueueSuccess({
              enqueuedGroups: enqueuedGroups,
            })
          ),
          catchError((error) =>
            of(OfflineQueueApiActions.searchOfflineQueueFailure({ error }))
          )
        )
      )
    )
  );

  processsOfflineQueue$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(OfflineQueueApiActions.processOfflineQueue),
        exhaustMap(() => this.apiService.processOfflineQueue())
      ),
    { dispatch: false }
  );
}
