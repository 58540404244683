import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { AuthService } from "@app/services/auth.service";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { concatLatestFrom } from "@ngrx/operators";
import { Store } from "@ngrx/store";
import { of } from "rxjs";
import { catchError, exhaustMap, filter, map, tap } from "rxjs/operators";
import {
  AuthApiActions,
  AuthPageActions,
  initializeAppAction,
} from "../actions";
import { selectAuthForm, selectIsLoggedIn } from "../selectors";

@Injectable()
export class AuthEffects {
  constructor(
    private actions$: Actions,
    private store: Store,
    private router: Router,
    private authService: AuthService
  ) {}

  init$ = createEffect(() =>
    this.actions$.pipe(
      ofType(initializeAppAction),
      map(() =>
        AuthApiActions.init({
          loggedIn: this.authService.isLoggedIn(),
          user: this.authService.getLoggedUser(),
        })
      )
    )
  );

  login$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthPageActions.login),
      concatLatestFrom(() => this.store.select(selectAuthForm)),
      exhaustMap(([, form]) => {
        if (!form.isValid) {
          return of(AuthApiActions.authFormInvalid());
        }
        return this.authService.login(form.value).pipe(
          map(() =>
            AuthApiActions.loginSuccess({
              loggedIn: this.authService.isLoggedIn(),
              user: this.authService.getLoggedUser(),
            })
          ),
          tap(() => this.router.navigate(["/"])),
          catchError((error) => of(AuthApiActions.loginFailure({ error })))
        );
      })
    )
  );

  load$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        AuthApiActions.init,
        AuthApiActions.loginSuccess,
        AuthApiActions.setCompanySuccess
      ),
      concatLatestFrom(() => this.store.select(selectIsLoggedIn)),
      filter(([, isLoggedIn]) => isLoggedIn),
      map(() => AuthApiActions.load())
    )
  );
}
