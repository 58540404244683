import { inject, Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { ApiService } from "@app/api/api.service";
import { getApiId } from "@app/api/utils";
import {
  IRiskAssessmentForm,
  RISK_ASSESSMENT_MODEL,
  RiskAssessment,
  RiskAssessmentApiValue,
  RiskAssessmentFormValue,
} from "@app/models";
import { Platform } from "@ionic/angular";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { concatLatestFrom } from "@ngrx/operators";
import { Store } from "@ngrx/store";
import {
  catchError,
  EMPTY,
  exhaustMap,
  filter,
  from,
  map,
  mergeMap,
  of,
  switchMap,
  tap,
} from "rxjs";
import {
  OfflineQueueApiActions,
  OperationApiActions,
  OperationViewPageActions,
  RiskAssessmentApiActions,
  RiskAssessmentPutPageActions,
  RiskAssessmentViewPageActions,
} from "../actions";
import {
  selectLoggedUserIsPro,
  selectOperationId,
  selectOperationRiskAssessments,
  selectOperationSyncOptions,
  selectRiskAssessment,
  selectRiskAssessmentForm,
  selectRiskAssessmentId,
} from "../selectors";

export const RISK_ASSESSMENT_REPORT = "dopa.risk_assessment_report";
export const RISK_ASSESSMENT_CONCURRENCY = 1;

@Injectable()
export class RiskAssessmentEffects {
  private store = inject(Store);
  private router = inject(Router);
  private platform = inject(Platform);
  private apiService = inject(ApiService);
  private actions$ = inject(Actions);

  url = ["/", "risk-assessments"];

  constructor() {}

  searchOperations$ = createEffect(() =>
    this.actions$.pipe(
      ofType(OperationApiActions.searchOperationsSuccess),
      concatLatestFrom(() => this.store.select(selectLoggedUserIsPro)),
      map(([, loggerUserIsPro]) => {
        if (loggerUserIsPro) {
          return RiskAssessmentApiActions.searchRiskAssessments();
        } else {
          return RiskAssessmentApiActions.searchRiskAssessmentsSuccess({
            assessments: [],
          });
        }
      })
    )
  );

  searchRiskAssessments$ = createEffect(() =>
    this.actions$.pipe(
      ofType(RiskAssessmentApiActions.searchRiskAssessments),
      switchMap(() =>
        this.apiService.search(RISK_ASSESSMENT_MODEL).pipe(
          map((apiData) =>
            RiskAssessmentApiActions.searchRiskAssessmentsSuccess({
              assessments: apiData.map((item) => new RiskAssessment(item)),
            })
          ),
          catchError((error) =>
            of(RiskAssessmentApiActions.searchRiskAssessmentsFailure({ error }))
          )
        )
      )
    )
  );

  ensureRiskAssessmentReports$ = createEffect(() =>
    this.actions$.pipe(
      ofType(RiskAssessmentApiActions.searchRiskAssessmentsSuccess),
      switchMap(({ assessments }) =>
        from(assessments).pipe(
          mergeMap(
            (assessment) =>
              this.apiService
                .ensureReport(RISK_ASSESSMENT_REPORT, assessment._id)
                .pipe(
                  map(() =>
                    RiskAssessmentApiActions.ensureRiskAssessmentReportSuccess({
                      assessment,
                    })
                  ),
                  catchError((error) =>
                    of(
                      RiskAssessmentApiActions.ensureRiskAssessmentReportFailure(
                        {
                          assessment,
                          error,
                        }
                      )
                    )
                  )
                ),
            RISK_ASSESSMENT_CONCURRENCY
          )
        )
      )
    )
  );

  openOrCreateRiskAssessment$ = createEffect(() =>
    this.actions$.pipe(
      ofType(OperationViewPageActions.openRiskAssessments),
      concatLatestFrom(() => [
        this.store.select(selectOperationId),
        this.store.select(selectOperationRiskAssessments),
        this.store.select(selectOperationSyncOptions),
      ]),
      exhaustMap(([, operationId, assessments, sync]) => {
        if (assessments.length > 1) {
          return of(
            RiskAssessmentApiActions.listRiskAssessments({ operationId })
          );
        }
        if (assessments.length == 1) {
          return of(
            RiskAssessmentApiActions.viewRiskAssessment({
              assessment: assessments[0],
            })
          );
        }
        if (assessments.length == 0) {
          const values: IRiskAssessmentForm = { operation_id: operationId };
          return this.apiService
            .create(RISK_ASSESSMENT_MODEL, values, sync)
            .pipe(
              map((apiData) =>
                RiskAssessmentApiActions.createRiskAssessmentSuccess({
                  assessment: new RiskAssessment(apiData),
                })
              ),
              catchError((error) =>
                of(
                  RiskAssessmentApiActions.createRiskAssessmentFailure({
                    error,
                  })
                )
              )
            );
        }
        return EMPTY;
      })
    )
  );

  openRiskAssessmentListPage$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(RiskAssessmentApiActions.listRiskAssessments),
        tap(({ operationId }) =>
          this.router.navigate([...this.url, "operation", operationId])
        )
      ),
    { dispatch: false }
  );

  openRiskAssessmentViewPage$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(
          RiskAssessmentApiActions.viewRiskAssessment,
          RiskAssessmentApiActions.createRiskAssessmentSuccess,
          RiskAssessmentApiActions.updateRiskAssessmentSuccess
        ),
        tap(({ type, assessment }) =>
          this.router.navigate([...this.url, "view", getApiId(assessment)], {
            replaceUrl:
              type == RiskAssessmentApiActions.updateRiskAssessmentSuccess.type,
          })
        )
      ),
    { dispatch: false }
  );

  openRiskAssessmentEditPage$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(RiskAssessmentViewPageActions.editRiskAssessment),
        concatLatestFrom(() => this.store.select(selectRiskAssessmentId)),
        tap(([, assessmentId]) => {
          this.router.navigate([...this.url, "edit", assessmentId]);
        })
      ),
    { dispatch: false }
  );

  setOperation$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        RiskAssessmentViewPageActions.setRiskAssessment,
        RiskAssessmentPutPageActions.setRiskAssessment
      ),
      concatLatestFrom(() => this.store.select(selectRiskAssessment)),
      map(([, { operation_id: operationId }]) =>
        RiskAssessmentApiActions.setOperation({ operationId })
      )
    )
  );

  setRiskAssessmentForm$ = createEffect(() =>
    this.actions$.pipe(
      ofType(RiskAssessmentPutPageActions.setRiskAssessment),
      concatLatestFrom(() => this.store.select(selectRiskAssessment)),
      map(([, assessment]) =>
        RiskAssessmentApiActions.setRiskAssessmentForm({
          riskAssessmentFormValue: new RiskAssessmentFormValue(assessment),
        })
      )
    )
  );

  updateRiskAssessment$ = createEffect(() =>
    this.actions$.pipe(
      ofType(RiskAssessmentPutPageActions.updateRiskAssessment),
      concatLatestFrom(() => [
        this.store.select(selectRiskAssessmentId),
        this.store.select(selectRiskAssessmentForm),
        this.store.select(selectOperationSyncOptions),
      ]),
      exhaustMap(([, assessmentId, form, sync]) => {
        if (!form.isValid) {
          return of(RiskAssessmentApiActions.riskAssessmentFormInvalid());
        }
        const values = new RiskAssessmentApiValue(form.value);
        return this.apiService
          .update(RISK_ASSESSMENT_MODEL, assessmentId, values, sync)
          .pipe(
            map((apiData) =>
              RiskAssessmentApiActions.updateRiskAssessmentSuccess({
                assessment: new RiskAssessment(apiData),
              })
            ),
            catchError((error) =>
              of(
                RiskAssessmentApiActions.updateRiskAssessmentFailure({ error })
              )
            )
          );
      })
    )
  );

  openRiskAssessmentReport$ = createEffect(() =>
    this.actions$.pipe(
      ofType(RiskAssessmentViewPageActions.openRiskAssessmentReport),
      concatLatestFrom(() => this.store.select(selectRiskAssessmentId)),
      exhaustMap(([, assessmentId]) =>
        this.apiService.openReport(RISK_ASSESSMENT_REPORT, assessmentId)
      ),
      map(() => RiskAssessmentApiActions.openRiskAssessmentReportSuccess),
      catchError((error) =>
        of(RiskAssessmentApiActions.openRiskAssessmentReportFailure({ error }))
      )
    )
  );

  downloadRiskAssessmentReport$ = createEffect(() =>
    this.actions$.pipe(
      ofType(RiskAssessmentViewPageActions.downloadRiskAssessmentReport),
      concatLatestFrom(() => this.store.select(selectRiskAssessment)),
      exhaustMap(([, assessment]) =>
        this.apiService.downloadReport(
          RISK_ASSESSMENT_REPORT,
          assessment._id,
          assessment.name
        )
      ),
      map(() => RiskAssessmentApiActions.downloadRiskAssessmentReportSuccess()),
      catchError((error) =>
        of(
          RiskAssessmentApiActions.downloadRiskAssessmentReportFailure({
            error,
          })
        )
      )
    )
  );

  syncRiskAssessmentSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        OfflineQueueApiActions.ApiCreateSuccess,
        OfflineQueueApiActions.ApiUpdateSuccess
      ),
      filter(({ model }) => model == RISK_ASSESSMENT_MODEL),
      map(({ data }) =>
        RiskAssessmentApiActions.syncRiskAssessmentSuccess({
          assessment: new RiskAssessment(data),
        })
      )
    )
  );
}
