import { ActionReducerMap } from "@ngrx/store";
import { attachmentsReducer } from "./attachments/attachments.reducer";
import { authReducer } from "./auth/auth.reducer";
import { checklistLineReducer } from "./checklist-lines/checklist-lines.reducer";
import { checklistTemplateReducer } from "./checklist-templates/checklist-templates.reducer";
import { checklistReducer } from "./checklists/checklists.reducer";
import { companyReducer } from "./companies/companies.reducer";
import { diveJobReducer } from "./dive-jobs/dive-jobs.reducer";
import { diveLogLineReducer } from "./dive-log-lines/dive-log-lines.reducer";
import { diveLogTemplateReducer } from "./dive-log-templates/dive-log-templates.reducer";
import { diveLogReducer } from "./dive-logs/dive-logs.reducer";
import { diveSiteReducer } from "./dive-sites/dive-sites.reducer";
import { offlineQueueReducer } from "./offline-queue/offline-queue.reducer";
import { operationReducer } from "./operations/operations.reducer";
import { partnerReducer } from "./partners/partners.reducer";
import { riskAssessmentReducer } from "./risk-assessments/risk-assessments.reducer";
import { toolboxTalkTemplateReducer } from "./toolbox-talk-templates/toolbox-talk-templates.reducer";
import { toolboxTalkReducer } from "./toolbox-talks/toolbox-talk.reducer";
import { userReducer } from "./users/users.reducer";

export const rootReducers: ActionReducerMap<any> = {
  auth: authReducer,
  company: companyReducer,
  users: userReducer,
  partners: partnerReducer,
  diveJobs: diveJobReducer,
  diveSites: diveSiteReducer,
  operations: operationReducer,
  toolboxTalks: toolboxTalkReducer,
  toolboxTalkTemplates: toolboxTalkTemplateReducer,
  checklists: checklistReducer,
  checklistLines: checklistLineReducer,
  checklistTemplates: checklistTemplateReducer,
  riskAssessments: riskAssessmentReducer,
  diveLogs: diveLogReducer,
  diveLogLines: diveLogLineReducer,
  diveLogTemplates: diveLogTemplateReducer,
  attachments: attachmentsReducer,
  offlineQueue: offlineQueueReducer,
};
