import { inject, Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { ApiService } from "@app/api/api.service";
import {
  DIVE_OPERATION_MODEL,
  DiveOperation,
  DiveOperationApiValue,
  DiveOperationFormValue,
} from "@app/models";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { concatLatestFrom } from "@ngrx/operators";
import { Store } from "@ngrx/store";
import { from, of } from "rxjs";
import {
  catchError,
  exhaustMap,
  filter,
  map,
  switchMap,
  tap,
} from "rxjs/operators";
import {
  AuthApiActions,
  OfflineQueueApiActions,
  OperationTimesheetsPutPageActions,
} from "../actions";
import { getApiId } from "../model";
import {
  OperationApiActions,
  OperationListPageActions,
  OperationPutPageActions,
  OperationTimesheetsListPageActions,
  OperationViewPageActions,
} from "./actions";
import {
  selectOperation,
  selectOperationForm,
  selectOperationId,
} from "./operations.selectors";

@Injectable()
export class OperationEffects {
  private store = inject(Store);
  private router = inject(Router);
  private apiService = inject(ApiService);
  private actions$ = inject(Actions);

  url = ["/", "operations"];
  timesheetUrl = ["/", "timesheets", "operation"];

  constructor() {}

  loadData$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthApiActions.load),
      map(() => OperationListPageActions.searchOperations({}))
    )
  );

  searchOperations$ = createEffect(() =>
    this.actions$.pipe(
      ofType(OperationListPageActions.searchOperations),
      switchMap(() =>
        from(this.apiService.search(DIVE_OPERATION_MODEL)).pipe(
          map((apiData) => {
            return OperationApiActions.searchOperationsSuccess({
              operations: apiData.map((data) => new DiveOperation(data)),
            });
          }),
          catchError((error) =>
            of(OperationApiActions.searchOperationsFailure({ error }))
          )
        )
      )
    )
  );

  openOperationCreatePage$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(OperationListPageActions.createOperation),
        tap(() => this.router.navigate([...this.url, "create"]))
      ),
    { dispatch: false }
  );

  openOperationViewPage$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(
          OperationListPageActions.viewOperation,
          OperationApiActions.createOperationSuccess,
          OperationApiActions.updateOperationSuccess
        ),
        tap(({ operation }) =>
          this.router.navigate([...this.url, "view", getApiId(operation)], {
            replaceUrl: true,
          })
        )
      ),
    { dispatch: false }
  );

  openOperationEditPage$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(OperationViewPageActions.editOperation),
        tap(({ operation }) =>
          this.router.navigate([...this.url, "edit", getApiId(operation)])
        )
      ),
    { dispatch: false }
  );

  openOperationTimesheetsListPage$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(
          OperationViewPageActions.openTimesheets,
          OperationApiActions.updateOperationTimesheetsSuccess
        ),
        concatLatestFrom(() => this.store.select(selectOperationId)),
        tap(([, operationId]) => {
          this.router.navigate([...this.timesheetUrl, operationId]);
        })
      ),
    { dispatch: false }
  );

  openOperationTimesheetsEditPage$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(OperationTimesheetsListPageActions.updateTimesheets),
        concatLatestFrom(() => this.store.select(selectOperationId)),
        tap(([, operationId]) => {
          this.router.navigate([...this.timesheetUrl, "edit", operationId]);
        })
      ),
    { dispatch: false }
  );

  setOperationForm$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        OperationPutPageActions.setOperation,
        OperationTimesheetsPutPageActions.setOperation
      ),
      concatLatestFrom(() => this.store.select(selectOperation)),
      map(([_, operation]) =>
        OperationApiActions.setOperationForm({
          operationFormValue: new DiveOperationFormValue(operation),
        })
      )
    )
  );

  createOperation$ = createEffect(() =>
    this.actions$.pipe(
      ofType(OperationPutPageActions.createOperation),
      concatLatestFrom(() => [this.store.select(selectOperationForm)]),
      exhaustMap(([, form]) => {
        if (!form.isValid) {
          return of(OperationApiActions.operationFormInvalid());
        }
        const values = new DiveOperationApiValue({
          ...form.value,
          state: "draft",
        });
        return this.apiService.create(DIVE_OPERATION_MODEL, values).pipe(
          map((apiData) =>
            OperationApiActions.createOperationSuccess({
              operation: new DiveOperation(apiData),
            })
          ),
          catchError((error) =>
            of(OperationApiActions.createOperationFailure({ error }))
          )
        );
      })
    )
  );

  updateOperation$ = createEffect(() =>
    this.actions$.pipe(
      ofType(OperationPutPageActions.updateOperation),
      concatLatestFrom(() => [
        this.store.select(selectOperationId),
        this.store.select(selectOperationForm),
      ]),
      exhaustMap(([, operationId, form]) => {
        if (!form.isValid) {
          return of(OperationApiActions.operationFormInvalid());
        }
        const values = new DiveOperationApiValue(form.value);
        return this.apiService
          .update(DIVE_OPERATION_MODEL, operationId, values)
          .pipe(
            map((apiData) =>
              OperationApiActions.updateOperationSuccess({
                operation: new DiveOperation(apiData),
              })
            ),
            catchError((error) =>
              of(OperationApiActions.updateOperationFailure({ error }))
            )
          );
      })
    )
  );

  updateOperationTimesheets$ = createEffect(() =>
    this.actions$.pipe(
      ofType(OperationTimesheetsPutPageActions.updateTimesheets),
      concatLatestFrom(() => [
        this.store.select(selectOperationId),
        this.store.select(selectOperationForm),
      ]),
      exhaustMap(([, operationId, form]) => {
        if (!form.isValid) {
          return of(OperationApiActions.operationFormInvalid());
        }
        const values = new DiveOperationApiValue(form.value);
        return this.apiService
          .update(DIVE_OPERATION_MODEL, operationId, values)
          .pipe(
            map((apiData) =>
              OperationApiActions.updateOperationTimesheetsSuccess({
                operation: new DiveOperation(apiData),
              })
            ),
            catchError((error) =>
              of(
                OperationApiActions.updateOperationTimesheetsFailure({ error })
              )
            )
          );
      })
    )
  );

  updateOperationState$ = createEffect(() =>
    this.actions$.pipe(
      ofType(OperationViewPageActions.updateOperationState),
      concatLatestFrom(() => this.store.select(selectOperationId)),
      exhaustMap(([action, operationId]) =>
        this.apiService
          .update(
            DIVE_OPERATION_MODEL,
            operationId,
            new DiveOperationApiValue({ state: action.state })
          )
          .pipe(
            map((apiData) =>
              OperationApiActions.updateOperationSuccess({
                operation: new DiveOperation(apiData),
              })
            ),
            catchError((error) =>
              of(OperationApiActions.updateOperationFailure({ error }))
            )
          )
      )
    )
  );

  archiveOperation$ = createEffect(() =>
    this.actions$.pipe(
      ofType(OperationListPageActions.archiveOperation),
      exhaustMap((action) =>
        this.apiService
          .update(DIVE_OPERATION_MODEL, action.id, { active: false })
          .pipe(
            map(() =>
              OperationApiActions.deleteOperationSuccess({ id: action.id })
            ),
            catchError((error) =>
              of(OperationApiActions.deleteOperationFailure({ error }))
            )
          )
      )
    )
  );

  syncOperationSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        OfflineQueueApiActions.ApiCreateSuccess,
        OfflineQueueApiActions.ApiUpdateSuccess
      ),
      filter(
        (action) =>
          action.model == DIVE_OPERATION_MODEL &&
          (!("active" in action.data) || !!action.data.active)
      ),
      map(({ data }) =>
        OperationApiActions.synchronizeOperationSuccess({
          operation: new DiveOperation(data),
        })
      )
    )
  );

  /*
  syncOperationGroupPending$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SynchronizerApiActions.scheduleSynchronizerRequestSuccess),
      filter(({ syncGroup }) => syncGroup.sync_model == OPERATION_MODEL),
      concatLatestFrom(() => this.store.select(selectAllOperations)),
      map(([{ syncGroup }, operations]) =>
        operations.find((operation) => operation._id == syncGroup.sync_id)
      ),
      filter((operation) => !!operation),
      map((operation) =>
        OperationApiActions.synchronizeOperationGroupPending({ operation })
      )
    )
  );

  syncOperationGroupStarted$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SynchronizerApiActions.processSynchronizerGroup),
      filter(({ syncGroup }) => syncGroup.sync_model == OPERATION_MODEL),
      concatLatestFrom(() => this.store.select(selectAllOperations)),
      map(([{ syncGroup }, operations]) =>
        operations.find((operation) => operation._id == syncGroup.sync_id)
      ),
      filter((operation) => !!operation),
      map((operation) =>
        OperationApiActions.synchronizeOperationGroupStarted({ operation })
      )
    )
  );

  syncOperationGroupSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SynchronizerApiActions.processSynchronizerGroup),
      filter(({ syncGroup }) => syncGroup.sync_model == OPERATION_MODEL),
      concatLatestFrom(() => this.store.select(selectAllOperations)),
      map(([{ syncGroup }, operations]) =>
        operations.find((operation) => operation._id == syncGroup.sync_id)
      ),
      filter((operation) => !!operation),
      map((operation) =>
        OperationApiActions.synchronizeOperationGroupSuccess({ operation })
      )
    )
  );

  syncOperationGroupFailure$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SynchronizerApiActions.processSynchronizerGroupFailure),
      filter(({ syncGroup }) => syncGroup.sync_model == OPERATION_MODEL),
      concatLatestFrom(() => this.store.select(selectAllOperations)),
      map(([{ syncGroup }, operations]) =>
        operations.find((operation) => operation._id == syncGroup.sync_id)
      ),
      filter((operation) => !!operation),
      map((operation) =>
        OperationApiActions.synchronizeOperationGroupFailure({ operation })
      )
    )
  );
  */
}
