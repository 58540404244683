import { inject, Injectable } from "@angular/core";
import { ApiService } from "@app/api/api.service";
import { DIVE_LOG_TEMPLATE_MODEL, DiveLogTemplate } from "@app/models";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { of } from "rxjs";
import { catchError, map, switchMap } from "rxjs/operators";
import { AuthApiActions } from "../actions";
import { DiveLogTemplateApiActions } from "./actions";

@Injectable()
export class DiveLogTemplateEffects {
  private apiService = inject(ApiService);
  private actions$ = inject(Actions);

  constructor() {}

  loadData$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthApiActions.load),
      map(() => DiveLogTemplateApiActions.searchDiveLogTemplates())
    )
  );

  searchDiveLogTemplates$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DiveLogTemplateApiActions.searchDiveLogTemplates),
      switchMap(() =>
        this.apiService.search(DIVE_LOG_TEMPLATE_MODEL).pipe(
          map((apiData) =>
            DiveLogTemplateApiActions.searchDiveLogTemplatesSuccess({
              diveLogTemplates: apiData.map(
                (item) => new DiveLogTemplate(item)
              ),
            })
          ),
          catchError((error) =>
            of(
              DiveLogTemplateApiActions.searchDiveLogTemplatesFailure({ error })
            )
          )
        )
      )
    )
  );
}
