import { inject, Injectable } from "@angular/core";
import { ApiService } from "@app/api/api.service";
import { DIVE_SITE_MODEL, DiveSite } from "@app/models";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { catchError, map, of, switchMap } from "rxjs";
import { AuthApiActions } from "../actions";
import { DiveSiteApiActions } from "./actions";

@Injectable()
export class DiveSitesEffects {
  private apiService = inject(ApiService);
  private actions$ = inject(Actions);

  constructor() {}

  loadData$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthApiActions.load),
      map(() => DiveSiteApiActions.searchDiveSites())
    )
  );

  searchDiveSites$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DiveSiteApiActions.searchDiveSites),
      switchMap(() =>
        this.apiService.search(DIVE_SITE_MODEL).pipe(
          map((apiData) =>
            DiveSiteApiActions.searchDiveSitesSuccess({
              diveSites: apiData.map((item) => new DiveSite(item)),
            })
          ),
          catchError((error) =>
            of(DiveSiteApiActions.searchDiveSitesFailure({ error }))
          )
        )
      )
    )
  );
}
