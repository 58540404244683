import { inject, Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { ApiService } from "@app/api/api.service";
import { Checklist, CHECKLIST_MODEL, IChecklistForm } from "@app/models";
import { selectOperationChecklists } from "@app/state/selectors";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { concatLatestFrom } from "@ngrx/operators";
import { Store } from "@ngrx/store";
import { EMPTY, of } from "rxjs";
import {
  catchError,
  exhaustMap,
  filter,
  map,
  switchMap,
  tap,
} from "rxjs/operators";
import {
  ChecklistLineApiActions,
  ChecklistLinePutPageActions,
  OfflineQueueApiActions,
} from "../actions";
import {
  OperationApiActions,
  OperationViewPageActions,
} from "../operations/actions";
import {
  selectChecklist,
  selectChecklistId,
  selectOperationId,
  selectOperationSyncOptions,
} from "../selectors";
import { ChecklistApiActions, ChecklistViewPageActions } from "./actions";

export const PATH_ID = "checklists";

@Injectable()
export class ChecklistEffects {
  private store = inject(Store);
  private router = inject(Router);
  private apiService = inject(ApiService);
  private actions$ = inject(Actions);

  url = ["/", PATH_ID];

  constructor() {}

  searchOperations$ = createEffect(() =>
    this.actions$.pipe(
      ofType(OperationApiActions.searchOperationsSuccess),
      map(() => ChecklistApiActions.searchChecklists())
    )
  );

  searchChecklists$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ChecklistApiActions.searchChecklists),
      switchMap(() =>
        this.apiService.search(CHECKLIST_MODEL).pipe(
          map((apiData) =>
            ChecklistApiActions.searchChecklistsSuccess({
              checklists: apiData.map((item) => new Checklist(item)),
            })
          ),
          catchError((error) =>
            of(ChecklistApiActions.searchChecklistsFailure({ error }))
          )
        )
      )
    )
  );

  setOperation$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        ChecklistViewPageActions.setChecklist,
        ChecklistLinePutPageActions.setChecklist
      ),
      concatLatestFrom(() => this.store.select(selectChecklist)),
      map(([, { operation_id: operationId }]) =>
        ChecklistApiActions.setOperation({ operationId })
      )
    )
  );

  openOrCreateChecklist$ = createEffect(() =>
    this.actions$.pipe(
      ofType(OperationViewPageActions.openChecklists),
      concatLatestFrom(() => [
        this.store.select(selectOperationId),
        this.store.select(selectOperationChecklists),
        this.store.select(selectOperationSyncOptions),
      ]),
      exhaustMap(([, operationId, checklists, sync]) => {
        if (checklists.length > 1) {
          return of(ChecklistApiActions.listChecklists({ operationId }));
        }
        if (checklists.length == 1) {
          return of(
            ChecklistApiActions.viewChecklist({
              checklist: checklists[0],
            })
          );
        }
        if (checklists.length == 0) {
          const values: IChecklistForm = { operation_id: operationId };
          return this.apiService.create(CHECKLIST_MODEL, values, sync).pipe(
            map((apiData) =>
              ChecklistApiActions.createChecklistSuccess({
                checklist: new Checklist(apiData),
              })
            ),
            catchError((error) =>
              of(ChecklistApiActions.createChecklistFailure({ error }))
            )
          );
        }
        return EMPTY;
      })
    )
  );

  openChecklistListPage$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(ChecklistApiActions.listChecklists),
        tap(({ operationId }) =>
          this.router.navigate([...this.url, "operation", operationId])
        )
      ),
    { dispatch: false }
  );

  openChecklistViewPage$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(
          ChecklistApiActions.viewChecklist,
          ChecklistApiActions.createChecklistSuccess,
          ChecklistLineApiActions.createChecklistLineSuccess,
          ChecklistLineApiActions.updateChecklistLineSuccess
        ),
        concatLatestFrom(() => this.store.select(selectChecklistId)),
        tap(([{ type }, checklistId]) =>
          this.router.navigate([...this.url, "view", checklistId], {
            replaceUrl:
              type == ChecklistLineApiActions.createChecklistLineSuccess.type ||
              type == ChecklistLineApiActions.updateChecklistLineSuccess.type,
          })
        )
      ),
    { dispatch: false }
  );

  syncChecklistSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        OfflineQueueApiActions.ApiCreateSuccess,
        OfflineQueueApiActions.ApiUpdateSuccess
      ),
      filter(({ model }) => model == CHECKLIST_MODEL),
      map(({ data }) =>
        ChecklistApiActions.syncChecklistSuccess({
          checklist: new Checklist(data),
        })
      )
    )
  );
}
