import {
  HTTP_INTERCEPTORS,
  provideHttpClient,
  withInterceptorsFromDi,
} from "@angular/common/http";
import { APP_INITIALIZER, enableProdMode } from "@angular/core";
import { bootstrapApplication } from "@angular/platform-browser";
import {
  PreloadAllModules,
  provideRouter,
  RouteReuseStrategy,
  withPreloading,
} from "@angular/router";
import { appInitializer } from "@app/app.initializer";
import {
  AttachmentsEfffects,
  AuthEffects,
  ChecklistEffects,
  ChecklistLineEffects,
  ChecklistTemplateEffects,
  CompanyEffects,
  DiveJobsEffects,
  DiveLogEffects,
  DiveLogLineEffects,
  DiveLogTemplateEffects,
  DiveSitesEffects,
  OfflineQueueEffects,
  OperationEffects,
  PartnerEffects,
  RiskAssessmentEffects,
  ToolboxTalkEffects,
  ToolboxTalkTemplateEffects,
  UserEffects,
} from "@app/state/effects";
import { rootReducers } from "@app/state/reducers";
import {
  IonicRouteStrategy,
  provideIonicAngular,
} from "@ionic/angular/standalone";
import { provideEffects } from "@ngrx/effects";
import { provideStore } from "@ngrx/store";
import { provideStoreDevtools } from "@ngrx/store-devtools";
import { AppComponent } from "./app/app.component";
import { routes } from "./app/app.routes";
import { AuthInterceptor } from "./app/services/auth.interceptor";
import { environment } from "./environments/environment";

if (environment.production) {
  enableProdMode();
}

// Sentry.init({
//   dsn: environment.sentryUrl,
//   release: `dopa-app-${2}`,
//   integrations: [
//     // TODO sentry
//     // new Sentry.BrowserTracing(),
//     //new Sentry.Replay(),
//   ],
//   tracesSampleRate: 1.0,
//   tracePropagationTargets: ["localhost", "https://app.dopa.marinedeep.com"],
//   replaysSessionSampleRate: 0.1,
//   replaysOnErrorSampleRate: 1.0,
//   ignoreErrors: [
//     // "Error: Token is expired or invalid!",
//     // "invalid_token",

//     "Token is expired or invalid!",

//     // "refresh_token",
//   ],
// });

bootstrapApplication(AppComponent, {
  providers: [
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    provideIonicAngular(),
    provideRouter(routes, withPreloading(PreloadAllModules)),

    {
      provide: APP_INITIALIZER,
      useFactory: appInitializer,
      multi: true,
    },

    // TODO CONVERT TO FUNCTIONAL INTERCEPTORS
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },

    provideHttpClient(withInterceptorsFromDi()),

    provideStore(rootReducers),
    provideEffects([
      AuthEffects,
      CompanyEffects,
      UserEffects,
      PartnerEffects,
      DiveJobsEffects,
      DiveSitesEffects,
      OperationEffects,
      ChecklistEffects,
      ChecklistLineEffects,
      ChecklistTemplateEffects,
      ToolboxTalkEffects,
      ToolboxTalkTemplateEffects,
      DiveLogEffects,
      DiveLogLineEffects,
      DiveLogTemplateEffects,
      AttachmentsEfffects,
      RiskAssessmentEffects,
      OfflineQueueEffects,
    ]),
    provideStoreDevtools({ maxAge: 25, logOnly: false }),
  ],
});
