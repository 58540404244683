import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from "@angular/common/http";
import { inject, Injectable } from "@angular/core";
import { AuthTokens } from "@app/models";
import { BehaviorSubject, Observable, throwError } from "rxjs";
import { catchError, filter, switchMap, take } from "rxjs/operators";
import { AuthService } from "./auth.service";

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  private authService = inject(AuthService);
  private isRefreshing = false;
  private refreshTokenSubject: BehaviorSubject<AuthTokens> =
    new BehaviorSubject<AuthTokens>(null);

  constructor() {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    if (this.authService.getAccessToken()) {
      request = this.addToken(request, this.authService.getAccessToken());
    }
    return next.handle(request).pipe(
      catchError((error: any) => {
        if (
          error instanceof HttpErrorResponse &&
          error.status === 0 &&
          error.error instanceof ProgressEvent
        ) {
          return throwError(
            () =>
              new HttpErrorResponse({
                error: {
                  error: "server_side_error",
                  error_descrip: "Server Side Error",
                },
                status: 404,
              })
          );
        } else if (
          error instanceof HttpErrorResponse &&
          error.status === 401 &&
          error.error.error == "invalid_access_token"
        ) {
          return this.handle401Error(request, next, error);
        } else {
          return throwError(() => error);
        }
      })
    );
  }

  private addToken(request: HttpRequest<any>, token: string) {
    return request.clone({
      setHeaders: {
        "Content-Type": "text/html",
        "Access-Token": token,
      },
    });
  }

  private handle401Error(
    request: HttpRequest<any>,
    next: HttpHandler,
    error: HttpErrorResponse
  ) {
    /**
     * In case of unexpected befaviour we can try throttleLogout
     * https://dev.to/ayeletdn/angular-http-interceptor-888
     *
     */

    if (!this.isRefreshing) {
      this.isRefreshing = true;
      this.refreshTokenSubject.next(null);

      return this.authService.refreshToken().pipe(
        switchMap((token: any) => {
          this.isRefreshing = false;
          this.refreshTokenSubject.next({
            accessToken: token.access_token,
          });
          return next.handle(this.addToken(request, token.access_token));
        }),
        catchError((error) => {
          if (this.authService.isLoggedIn()) {
            return this.handleReLogin(request, next);
          } else {
            this.refreshTokenSubject.next({ accessToken: null });
            this.authService.logout();
            return throwError(() => error);
          }
        })
      );
    } else {
      return this.refreshTokenSubject.pipe(
        filter((tokens) => tokens != null),
        take(1),
        switchMap((tokens) => {
          if (!!tokens.accessToken) {
            return next.handle(this.addToken(request, tokens.accessToken));
          } else {
            return throwError(() => error);
          }
        })
      );
    }
  }

  private handleReLogin(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return this.authService.promptReLogin().pipe(
      switchMap((accessToken) => {
        this.isRefreshing = false;
        this.refreshTokenSubject.next({ accessToken });
        return next.handle(this.addToken(request, accessToken));
      }),
      catchError(() => this.handleReLogin(request, next))
    );
  }
}
