import { inject, Injectable } from "@angular/core";
import { ApiService } from "@app/api/api.service";
import { DIVE_JOB_MODEL, DiveJob } from "@app/models";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { catchError, map, of, switchMap } from "rxjs";
import { AuthApiActions, DiveJobApiActions } from "../actions";

@Injectable()
export class DiveJobsEffects {
  private apiService = inject(ApiService);
  private actions$ = inject(Actions);

  constructor() {}

  loadData$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthApiActions.load),
      map(() => DiveJobApiActions.searchDiveJobs())
    )
  );

  searchDiveJobs$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DiveJobApiActions.searchDiveJobs),
      switchMap(() =>
        this.apiService.search(DIVE_JOB_MODEL).pipe(
          map((diveJobsData: DiveJob[]) =>
            DiveJobApiActions.searchDiveJobsSuccess({
              diveJobs: diveJobsData.map(
                (diveJobData) => new DiveJob(diveJobData)
              ),
            })
          ),
          catchError((error) =>
            of(DiveJobApiActions.searchDiveJobsFailure({ error }))
          )
        )
      )
    )
  );
}
