import { inject } from "@angular/core";
import { CanDeactivateFn } from "@angular/router";
import { AlertController } from "@ionic/angular";
import { Observable, from, of } from "rxjs";
import { switchMap, take } from "rxjs/operators";
import { FormGroupState } from "ngrx-forms";

export interface isDeactivatable {
  form$: Observable<FormGroupState<unknown>>;
  canLeavePage: () => Observable<boolean> | Promise<boolean> | boolean;
}

export const leavePageGuard: CanDeactivateFn<isDeactivatable> = (
  component
): Observable<boolean> => {
  const alertController = inject(AlertController);

  const alertControl = async (): Promise<boolean> => {
    const alert = await alertController.create({
      header: "Unsaved Changes",
      message: "Do you want to leave?",
      buttons: [
        {
          text: "No",
          role: "cancel",
        },
        {
          text: "Yes",
          role: "goBack",
        },
      ],
    });

    await alert.present();
    const data = await alert.onDidDismiss();

    return data.role === "goBack";
  };

  return component.form$.pipe(
    take(1),
    switchMap((form) => {
      if (form.isPristine) {
        return of(true);
      } else {
        return from(alertControl());
      }
    })
  );
};
