import { inject, Injectable } from "@angular/core";
import { ApiService } from "@app/api/api.service";
import { ResUser, USER_MODEL } from "@app/models";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { catchError, map, of, switchMap } from "rxjs";
import { AuthApiActions } from "../actions";
import { UserApiActions } from "./actions";

@Injectable()
export class UserEffects {
  private apiService = inject(ApiService);
  private actions$ = inject(Actions);

  constructor() {}

  loadData$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthApiActions.load),
      map(() => UserApiActions.searchUsers())
    )
  );

  searchUsers$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserApiActions.searchUsers),
      switchMap(() =>
        this.apiService.search(USER_MODEL).pipe(
          map((apiData) =>
            UserApiActions.searchUsersSuccess({
              users: apiData.map((item) => new ResUser(item)),
            })
          ),
          catchError((error) =>
            of(UserApiActions.searchUsersFailure({ error }))
          )
        )
      )
    )
  );
}
