import { Injectable, inject } from "@angular/core";
import { Router } from "@angular/router";
import { ApiService } from "@app/api/api.service";
import { DIVE_LOG_MODEL, DiveLog, IDiveLogForm } from "@app/models";
import { selectDiveLog, selectOperationDiveLogs } from "@app/state/selectors";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { concatLatestFrom } from "@ngrx/operators";
import { Store } from "@ngrx/store";
import { EMPTY, of } from "rxjs";
import {
  catchError,
  exhaustMap,
  filter,
  map,
  switchMap,
  tap,
} from "rxjs/operators";
import { DiveLogLineApiActions, OfflineQueueApiActions } from "../actions";
import {
  OperationApiActions,
  OperationViewPageActions,
} from "../operations/actions";
import { selectOperationId, selectOperationSyncOptions } from "../selectors";
import { DiveLogApiActions, DiveLogViewPageActions } from "./actions";
import { selectDiveLogId } from "./dive-logs.selectors";

@Injectable()
export class DiveLogEffects {
  private store = inject(Store);
  private router = inject(Router);
  private apiService = inject(ApiService);
  private actions$ = inject(Actions);

  url = ["/", "dive-logs"];

  constructor() {}

  searchOperations$ = createEffect(() =>
    this.actions$.pipe(
      ofType(OperationApiActions.searchOperationsSuccess),
      map(() => DiveLogApiActions.searchDiveLogs())
    )
  );

  searchDiveLogs$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DiveLogApiActions.searchDiveLogs),
      switchMap(() =>
        this.apiService.search(DIVE_LOG_MODEL).pipe(
          map((apiData) =>
            DiveLogApiActions.searchDiveLogsSuccess({
              diveLogs: apiData.map((item) => new DiveLog(item)),
            })
          ),
          catchError((error) =>
            of(DiveLogApiActions.searchDiveLogsFailure({ error }))
          )
        )
      )
    )
  );

  setOperation$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DiveLogViewPageActions.setDiveLog),
      concatLatestFrom(() => this.store.select(selectDiveLog)),
      map(([, { operation_id: operationId }]) =>
        DiveLogApiActions.setOperation({ operationId })
      )
    )
  );

  openOrCreateDiveLog$ = createEffect(() =>
    this.actions$.pipe(
      ofType(OperationViewPageActions.openDiveLogs),
      concatLatestFrom(() => [
        this.store.select(selectOperationId),
        this.store.select(selectOperationDiveLogs),
        this.store.select(selectOperationSyncOptions),
      ]),
      exhaustMap(([, operationId, diveLogs, sync]) => {
        if (diveLogs.length > 1) {
          return of(DiveLogApiActions.listDiveLogs({ operationId }));
        }
        if (diveLogs.length == 1) {
          return of(DiveLogApiActions.viewDiveLog({ diveLog: diveLogs[0] }));
        }
        if (diveLogs.length == 0) {
          const values: IDiveLogForm = { operation_id: operationId };
          return this.apiService.create(DIVE_LOG_MODEL, values, sync).pipe(
            map((apiData) =>
              DiveLogApiActions.createDiveLogSuccess({
                diveLog: new DiveLog(apiData),
              })
            ),
            catchError((error) =>
              of(DiveLogApiActions.createDiveLogFailure({ error }))
            )
          );
        }
        return EMPTY;
      })
    )
  );

  openDiveLogListPage$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(DiveLogApiActions.listDiveLogs),
        tap(({ operationId }) =>
          this.router.navigate([...this.url, "operation", operationId])
        )
      ),
    { dispatch: false }
  );

  openDiveLogViewPage$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(
          DiveLogApiActions.viewDiveLog,
          DiveLogApiActions.createDiveLogSuccess,
          DiveLogLineApiActions.createDiveLogLineSuccess,
          DiveLogLineApiActions.updateDiveLogLineSuccess
        ),
        concatLatestFrom(() => this.store.select(selectDiveLogId)),
        tap(([{ type }, diveLogId]) =>
          this.router.navigate([...this.url, "view", diveLogId], {
            replaceUrl:
              type == DiveLogLineApiActions.createDiveLogLineSuccess.type ||
              type == DiveLogLineApiActions.updateDiveLogLineSuccess.type,
          })
        )
      ),
    { dispatch: false }
  );

  syncDiveLogSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        OfflineQueueApiActions.ApiCreateSuccess,
        OfflineQueueApiActions.ApiUpdateSuccess
      ),
      filter(({ model }) => model == DIVE_LOG_MODEL),
      map(({ data }) =>
        DiveLogApiActions.syncDiveLogSuccess({
          diveLog: new DiveLog(data),
        })
      )
    )
  );
}
