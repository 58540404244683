import { Injectable } from "@angular/core";
import { CanActivate } from "@angular/router";
import { ToastService } from "@app/services";
import { selectToolboxTalksCanActivate } from "@app/state/selectors";
import { Store } from "@ngrx/store";
import { Observable } from "rxjs";
import { filter, take, tap } from "rxjs/operators";

@Injectable({
  providedIn: "root",
})
export class ToolboxTalkGuard implements CanActivate {
  constructor(private store: Store, private toastService: ToastService) {}

  waitForToolboxTalkToLoad(): Observable<boolean> {
    return this.store.select(selectToolboxTalksCanActivate).pipe(
      tap(
        (canActivate) =>
          !canActivate && this.toastService.show("Toolbox Talks loading...")
      ),
      filter((canActivate) => canActivate),
      take(1)
    );
  }

  canActivate(): Observable<boolean> {
    return this.waitForToolboxTalkToLoad();
  }
}
