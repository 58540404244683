import { inject, Injectable } from "@angular/core";
import { ApiService } from "@app/api/api.service";
import { TOOLBOX_TALK_TEMPLATE_MODEL, ToolboxTalkTemplate } from "@app/models";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { of } from "rxjs";
import { catchError, map, switchMap } from "rxjs/operators";
import { AuthApiActions } from "../actions";
import { ToolboxTalkTemplateApiActions } from "./actions";

@Injectable()
export class ToolboxTalkTemplateEffects {
  private apiService = inject(ApiService);
  private actions$ = inject(Actions);

  constructor() {}

  loadData$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthApiActions.load),
      map(() => ToolboxTalkTemplateApiActions.searchToolboxTalkTemplates())
    )
  );

  searchToolboxTalkTemplates$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ToolboxTalkTemplateApiActions.searchToolboxTalkTemplates),
      switchMap(() =>
        this.apiService.search(TOOLBOX_TALK_TEMPLATE_MODEL).pipe(
          map((apiData) =>
            ToolboxTalkTemplateApiActions.searchToolboxTalkTemplatesSuccess({
              toolboxTalkTemplates: apiData.map(
                (item) => new ToolboxTalkTemplate(item)
              ),
            })
          ),
          catchError((error) =>
            of(
              ToolboxTalkTemplateApiActions.searchToolboxTalkTemplatesFailure({
                error,
              })
            )
          )
        )
      )
    )
  );
}
