import { Injectable } from "@angular/core";
import { CanActivate } from "@angular/router";
import { ToastService } from "@app/services";
import { Store } from "@ngrx/store";
import { Observable, filter, take, tap } from "rxjs";
import { selectRiskAssessmentsCanActivate } from "../state/selectors";

@Injectable({
  providedIn: "root",
})
export class RiskAssessmentGuard implements CanActivate {
  constructor(private store: Store, private toastService: ToastService) {}

  waitForRiskAssessmentsToLoad(): Observable<boolean> {
    return this.store.select(selectRiskAssessmentsCanActivate).pipe(
      tap(
        (canActivate) =>
          !canActivate && this.toastService.show("Risk Assessments loading...")
      ),
      filter((canActivate) => canActivate),
      take(1)
    );
  }

  canActivate(): Observable<boolean> {
    return this.waitForRiskAssessmentsToLoad();
  }
}
