import { inject } from "@angular/core";
import { Platform } from "@ionic/angular";
import { Store } from "@ngrx/store";
import { from, Observable, switchMap, tap } from "rxjs";
import { AuthService } from "./services";
import { initializeAppAction } from "./state";

export function appInitializer() {
  const store = inject(Store);
  const platform = inject(Platform);
  const authService = inject(AuthService);
  return (): Observable<any> =>
    from(platform.ready()).pipe(
      switchMap(() => authService.init()),
      tap(() => {
        store.dispatch(initializeAppAction());
      })
    );
}
